

import { CF2Component } from 'javascript/lander/runtime'

export default class AppointmentSchedulerV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    if (this.requestFetcher){
      this.requestFetcher.abort()
    }
    this.requestFetcher = null
    this.requestFetcher = new globalThis.CFFetcher()
    const fetchUrl = `/user_pages/api/v1/appointments/event_types/${this.event_type.id}/calendar_config`
    this.requestFetcher.fetch(fetchUrl,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => response.json())
    .then((data) => {
      console.log({data})
      this.cronofy = CronofyElements.DateTimePicker({
        element_token: data.element_token,
        target_id: 'cronofy-date-time-picker',
        availability_query: JSON.parse(data.availability_query_json),
        styles: {
          prefix: 'cf2',
          colors: {
            buttonActive: data.active_color,
            buttonHover: data.hover_color,
            buttonConfirm: data.confirm_color,
          },
        },
        tzid: data.tzid,
        callback: (res) => {
          console.log({res})
        }
      })
    })
  }



}

window["AppointmentSchedulerV1"] = AppointmentSchedulerV1

